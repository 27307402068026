<template>
  <Header/>
  <div id="content" style="height: 100%;">
    <router-view/>
  </div>
  <Footer/>
  <div class="modal fade" id="version-modal" tabindex="-1" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column w-100 px-3">
                  <div class="d-flex flex-column justify-content-center align-items-center mb-3">
                    <span><i class="fas fs-2 warning fa-exclamation-triangle"></i></span>
                  </div>
                  <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <span class="poppins-bold fs-6 mb-1">{{$t("alert.error.versionMissmatch")}}</span>
                  </div>
                </div>
            </div>
            <div class="modal-footer pb-3">
                <div class="d-flex flex-row justify-content-center align-intems-center w-100">
                  <button type="submit" @click="reload()" class="btn btn-outline-primary btn-small mx-3">
                    {{$t("content.confirm")}}
                  </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/layout/header/Header.vue';
import Footer from '@/components/layout/footer/Footer.vue';
import {Modal} from "bootstrap";

export default {
  data() {
    return {
        versionModalObj: undefined
    }
  },
  mounted() {
    this.versionModalObj = new Modal(document.getElementById('version-modal'), {backdrop: 'static', keyboard: false});
    this.$store.dispatch('getVersion');
  },
  computed: {
    versionError() {
        let error = this.$store.getters['getIsDiffVer'];
        return error;
    }
  },
  watch: {
    versionError(newVal) {
        if (newVal) {
            this.versionModalObj.show();
        }
    }
  },
  methods: {
    reload() {
        location.reload();
    }
  },
  components: {
    Header,
    Footer,
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">

$yellow: #FFCD6C;
$dark-yellow: #F9B93E;
$blue: #00A0FF;
$dark-blue: #047CE5;
$black: #22304c;
$light-gray: #FAFAFA;
$pure-black: #000;
$scroll-thumb: #B8B8B8;
$scroll-track: #E6E6E6;
$scroll-thumb-hover: #979797;

body {
    background-color: rgba(0, 0, 0, 0.07) !important;
}

.font-yellow {
  color: $yellow !important;
}

.font-black{
  color: $black
}

.font-white {
    color: #fff !important;
}

.font-pure-black {
  color: $pure-black;
}

.font-dark-blue{
  color: $dark-blue;
}

.blue-gradient {
  background: linear-gradient(90deg, $blue 0%, $dark-blue 100%);
}

.yellow-gradient {
    background: linear-gradient(90deg, $yellow 0%, $dark-yellow 100%);;
}

.bg-yellow{
  background-color: $yellow!important;
}

.blue-gradient-v {
  background: linear-gradient(0deg, $blue 0%, $dark-blue 100%);
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./assets/fonts/montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-black';
  src: url('./assets/fonts/poppins/Poppins-Black.ttf');
}

.montserrat-bold {
  font-family: 'Montserrat-Bold';
}

.montserrat-semi-bold {
  font-family: 'Montserrat-SemiBold';
}

.montserrat-regular {
  font-family: 'Montserrat-Regular';
}

.poppins-bold {
  font-family: 'Poppins-Bold';
}

.poppins-semi-bold {
  font-family: 'Poppins-SemiBold';
}

.poppins-regular {
  font-family: 'Poppins-Regular';
}

.poppins-light  {
  font-family: 'Poppins-Light';
}

.poppins-black{
  font-family: 'Poppins-black';
}

.pointer {
  cursor: pointer;
}

.btn {
  padding: 0.7rem 0.75rem !important;
  border-radius: 3px !important;
}

.fs-regular {
    font-size: 1rem !important;
}

.fs-medium {
    font-size: 0.875rem !important;
}

.fs-small {
    font-size: 0.75rem !important; 
}

.btn-large {
  width: 216px;
}

.btn-small {
  width: 148px;
}

.text-gradient {
  background: -webkit-linear-gradient($blue 0%, $dark-blue 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &.underline{
    position: relative;
    &:after{
      height: 1px;
      width: 100%;
      background: linear-gradient(180deg, $blue 0%, $dark-blue 100%);
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.btn-primary {
  background: linear-gradient(180deg, $blue 0%, $dark-blue 100%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border: none !important;
  color: #fff;
  &:focus {
    background: linear-gradient(180deg, $blue 0%, $dark-blue 100%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12) !important;
  }
  &:hover {
    background: linear-gradient(180deg, $blue 0%, $dark-blue 100%) !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12) !important;
    color: #fff !important;
  }
  &.yellow-gradient {
    background: linear-gradient(180deg, $yellow 0%, $dark-yellow 100%);
    &:focus {
        background: linear-gradient(180deg, $yellow 0%, $dark-yellow 100%);
    }
    &:hover {
        background: linear-gradient(180deg, $yellow 0%, $dark-yellow 100%) !important;
    }
  } 
}



.btn-outline-primary {
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12));
  border-radius: 3px !important;
  border: 1px solid transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(180deg, $blue 0%, $dark-blue 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  &:focus {
    box-shadow: 2px 1000px 1px #fff inset !important;
  }
  &:hover {
      color: #0d6efd !important;
  }
}

#content {
    font-family: 'Poppins-Regular';
    color: $black;
    height: calc(100vh - 100px - 0.25rem);
    display: flex;
    width: 100%;
    position: relative;
    // padding: 24px 0rem 0rem 0rem;
    background-color: #fff;
    .link {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
    }
    .form-control {
        background-color: #fff;
        border: 0.8px solid rgba(216, 216, 216, 0.87) !important;
        box-sizing: border-box;
        border-radius: 3px;
        max-height: 40px;
        min-height: 40px;
        font-size: 0.875rem;
        color: #293644;
        &::placeholder {
            color: #C4C4C4;
        }
        &.field-error {
            border: 0.8px solid #FF4240 !important
        }
        &:focus {
            box-shadow: none;
        }
    }
    .form-auth {
        font-size: 1rem;
    }
    .vertical-separator {
        height: 24px;
        width: 1px;
        background-color: #E6E6E6;
    }
    .horizontal-separator {
        width: 100%;
        height: 1px;
        background-color: #D8D8D8;
    }

    @media screen and (max-width: 1280px) and (max-height: 600px) {
        min-height: calc(100vh - 128px);
    }

    @media screen and (max-width: 991px) {
        min-height: calc(100vh - 104px);
    }

}

.underline {
  text-decoration: underline !important;
}

.field-error {
  color: red;
}

.black-text {
    color: #22304c;
}

.text-primary {
  background:-moz-linear-gradient(180deg, $blue 0%, $dark-blue 100%);
  background: -webkit-linear-gradient(180deg, $blue 0%, $dark-blue 100%);
  background: linear-gradient(180deg, $blue 0%, $dark-blue 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color:transparent;
}

.text-primary-alt {
  background:-moz-linear-gradient(180deg, $yellow 0%, $dark-yellow 100%);
  background: -webkit-linear-gradient(180deg, $yellow 0%, $dark-yellow 100%);
  background: linear-gradient(180deg, $yellow 0%, $dark-yellow 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color:transparent;
}

.success-icon {
  font-size: 3rem;
  color: $blue;
}

.underliner {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-family: 'Poppins-Bold';
  position: relative;
  &::after {
    height: 3px;
    width: 6rem;
    background: linear-gradient(180deg, $blue 0%, $dark-blue 100%);
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.underliner1 {
  margin-bottom: 1rem;
  padding-bottom: 30px;
  position: relative;
  &::after {
    height: 2px;
    width: 20rem;
    background: linear-gradient(to right, transparent, grey, transparent);
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.card {
  box-shadow: none;
  box-shadow: 1px 0px 40px rgba(0, 0, 0, 0.07);
  border-radius: 3px !important;
  border: none !important;
}

.small{
  small,.small{
    font-size: 75%;
    line-height: 1;
    b{
      text-decoration: underline;
      letter-spacing: 1px;
      font-size: 90%;
    }
  }
}

.dropdown-toggle::after {
    border: none !important;
}

.multiselect {
  background-color: #fff;
  box-sizing: border-box;
  max-height: 40px;
  min-height: 40px;
  font-size: 0.875rem;
  .multiselect__select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &::before {
        display: none;
    }
  }
  &.multiselect--above {
    .multiselect__content-wrapper {
        bottom: auto;
    }
  }
  .multiselect__content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    .multiselect__content {
      max-width: 100%;
      position: relative;
    }
  }
  .multiselect__tags {
    border: 0.8px solid rgba(216, 216, 216, 0.87) !important;
    max-height: 40px;
    min-height: 40px;
    border-radius: 3px;
    font-size: 0.875rem;
    color: #293644;
    .multiselect__single, .multiselect__input, .multiselect__placeholder {
      font-size: 0.875rem !important;
    }
    .multiselect__placeholder {
        color: #C4C4C4;
    }
    .multiselect__tags-wrap {
        display: none;
    }
    .multiselect__input {
        padding: 0;
    }
  }
  .multiselect__option--highlight {
    background: #00a0ff !important;
    &::after {
      background: #00a0ff !important;
    }
  }
  .clear-container {
    position: sticky;
    bottom: 0;
    height: 40px;
    background: #fff;
  }
}

.multiselect--disabled {
    .multiselect__select {
        background: #fff;
    }
}

.password-check {
  p {
    display: inline-block;
    font-size: 0.88rem;
    margin-right: 1rem;

    &.valid-check {
      .fa-check {
        color: #00a0ff;
      }
    }

    &.invalid-check {
      filter: saturate(0);
      color: #C4C4C4;
    }
  }
}

.vuejs3-datepicker {
  &.date-error {
    .vuejs3-datepicker__inputvalue {
        border: 0.8px solid #FF4240 !important;
    }
  }
  width: 100%;
  svg {
    position: absolute;
  }
  .vuejs3-datepicker__inputvalue {
    padding: 0.375rem 0.75rem 0.375rem 2rem;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    border: 0.8px solid rgba(216, 216, 216, 0.87) !important;
    border-radius: 3px;
    font-size: 0.875rem;
    font-family: "Poppins-Regular";
    color: #293644;
    align-self: center;
  }
  .vuejs3-datepicker__value {
    padding: 0.375rem 0.75rem 0.375rem 1.5rem;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    border: 0.8px solid rgba(216, 216, 216, 0.87) !important;
    border-radius: 3px;
    .vuejs3-datepicker__icon {
      align-items: center;
    }
    .vuejs3-datepicker__content {
      font-size: 0.88rem;
      margin-left: 0;
      color: #293644;
      align-self: center;
    }
  }
  .vuejs3-datepicker__calendar {
    .vuejs3-datepicker__calendar-topbar {
      background: linear-gradient(180deg, #00A0FF 0%, #047CE5 100%);
    }
    .vuejs3-datepicker__calendar-actionarea {
      .cell.selected {
        border: 1px solid #047CE5;
        background: linear-gradient(180deg, #00A0FF 0%, #047CE5 100%);
      }
      .cell:not(.blank):not(.disabled).day:hover, .vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
        border: 1px solid #047CE5;
      }
    }
  }
}

#portfolio-options {
    .vuejs3-datepicker {
        .vuejs3-datepicker__inputvalue {
            min-width: 240px;
        }
    }
}

.scrollable{
  flex-wrap: nowrap;
  scrollbar-color: $scroll-thumb $scroll-track;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: $scroll-track;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: $scroll-track;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $scroll-thumb;
    mix-blend-mode: multiply;
  }

  &::-webkit-scrollbar-thumb:hover{
    background-color: $scroll-thumb-hover;
  }

  &.X-scrollable{
    display: flex;
    overflow-x: auto;
  }

  &.Y-scrollable{
    display: flex;
    overflow-y: auto;
  }

  .scrollable-item{
    overflow: visible;
  }
}

.modal {
    &.fade {
        &.show {
            background: rgba(238, 238, 238, 0.74);
            .modal-dialog {
                .modal-content {
                    border-radius: 0;
                    border: none;
                    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.07);
                    .modal-header {
                        padding: 1rem;
                        border: none;
                        display: flex;
                        justify-content: center;
                        .title {
                            font-size: 1rem;
                        }
                        .btn-primary {
                            position: absolute;
                            right: -3rem;
                            top: 0;
                            display: flex;
                            align-items: center;
                            padding: 0.5rem 0.75rem;
                            .icon {
                                font-size: 1.5rem;
                            }
                        }
                    }
                    .modal-body {
                        label {
                            font-size: 1.15rem;
                        }
                        $sdgs-card-front-bottom: 0px;/* make sure this value is 0 if showPercentage is false*/
                        $sdgs-card: 128px;
                        $sdgs-scrollable-max-width-small: 375px;
                        $sdgs-scrollable-max-width-narrow: 280px;
                        $sdgs-column-gap: 20px;
                        $sdgs-row-gap: 20px;
                        $sdgs-card-shadow: 0px 4px 16px -4px #00000020;
                        padding: 1rem 0 1rem 0;
                        font-size: 0.8rem;
                        .horizontal-separator {
                            margin: 0 -1rem;
                            min-width: calc(100% + 2rem);
                        }
                        .list {
                            margin: 0;
                            padding-left: 1rem;
                            .list-item::marker {
                                color: #00a0ff;
                            }
                        }
                        .sdgs-cards-container{
                            grid-template-columns: repeat(3, $sdgs-card);
                            grid-auto-rows: 1fr;
                            column-gap: $sdgs-column-gap;
                            row-gap: $sdgs-row-gap;
                            padding: 6px 6px 0 10px;
                            .sdgs-card{                                
                                border-radius: 3px;
                                &.card-1{
                                    grid-column-start: 1;
                                    grid-column-end: 2;
                                }
                                &.card-2{
                                    grid-column-start: 2;
                                    grid-column-end: 3;
                                }
                                &.card-3{
                                    grid-column-start: 3;
                                    grid-column-end: 4;
                            }
                            .scrollable{
                                scrollbar-color: #D8D8D8 #E6E6E600;
                                scrollbar-width: thin;
                                &.Y-scrollable{
                                max-height: $sdgs-card;
                                height: 100%;
                                padding: 22px 6px 4px;
                                row-gap: $sdgs-row-gap;
                                }
                                &::-webkit-scrollbar-track {
                                border-radius: 3px;
                                background-color: #E6E6E600;
                                }
                                &::-webkit-scrollbar {
                                width: 4px;
                                height: 4px;
                                background-color: #E6E6E600;
                                border-radius: 3px;
                                }
                                &::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                                background-color: #D8D8D8;
                                mix-blend-mode: multiply;
                                }
                            }
                            .sdgs-card-container{
                                .sdgs-card-inner{
                                  box-shadow: $sdgs-card-shadow;
                                width: $sdgs-card;
                                height: calc(#{$sdgs-card} + #{$sdgs-card-front-bottom});
                                .sdgs-card-front-inner{
                                    max-height: $sdgs-card;
                                    .sdgs-card-front-botton{
                                    height: $sdgs-card-front-bottom;
                                    line-height: $sdgs-card-front-bottom;
                                    }
                                }
                                }
                            }
                            }
                        }
                        .progress {
                            height: 0.5rem;
                            background-color: #e9e9e9;
                            .progress-bar {
                                background-color: #6ac7ff;
                            }
                        }
                        .carousel {
                            min-height: 120px;
                            .carousel-inner {
                                min-height: inherit;
                                .carousel-item {
                                    min-height: inherit;
                                    .item-inner {
                                        // min-height: inherit;
                                        .matches {
                                            list-style: none;
                                            padding: 0;
                                            .list-item {
                                                cursor: pointer;
                                                &:hover {
                                                    color: #00A0FF;
                                                }
                                                &.selected {
                                                    color: #047CE5;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .carousel-control-prev {
                                .carousel-control-prev-icon {
                                    background-image: url('./assets/images/prev-btn.svg');
                                    background-size: 50%;
                                }
                            }
                            .carousel-control-next {
                                .carousel-control-next-icon {
                                    background-image: url('./assets/images/next-btn.svg');
                                    background-size: 50%;
                                }
                            }
                        }
                        .warning {
                            color: #00A0FF;
                        }
                    }
                    .modal-footer {
                        border: none;
                    }
                }
              }
            }
        }
}

.disabled-by-capability{
  opacity: .6;
  cursor: pointer;
  filter: saturate(0.75);
  &.pointer-event-none{
    pointer-events: none!important;
  }
  >*{
    pointer-events: none;
  }
}

.Vue-Toastification__container {
    .Vue-Toastification__toast {
        font-family: 'Poppins-Regular' !important;
        &.Vue-Toastification__toast--error {
            background-color: #FF4240 !important;
        }
        &.Vue-Toastification__toast--success {
            background-color: #7BDF6B !important;
        }
        .Vue-Toastification__toast-body {
            font-size: 0.875rem !important;
        }
    }
}

input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    &[type=number] {
    -moz-appearance: textfield;
    }
}

.ml-auto {
    margin-left: auto;
}

.cursor-default {
    cursor: default;
}
.impact-chat-version {
      right: 5px;
      top: 2px;
      font-size: 10px;
      opacity: .5;
      position: absolute;
    }
#ai-chat {
   
    .chat-container {
        .chat {
            .message-container {
                .message {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 991px) {
    .col-xl-2-4 {
        flex: 0 0 auto !important;
        width: 208px !important;
    }
    .col-xl-9-6 {
        overflow: hidden !important;
        flex: 0 0 auto !important;
        width: calc(100% - 208px) !important;
    }
}

@media screen and (min-width: 1500px) {
  .custom-container {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 360px){
  .btn.btn-small{
    font-size: .875em!important;
  }
}

#chat-container {
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

    .pdf-link-container {
      margin-top: 10px;
    }

    .pdf-link-heading {
      font-weight:bold;
    } 

    .pdf-link-container-inner {
      
    }

    .pdf-link {
      color: #e2e2e2;
      text-decoration: none;
      display: flex !important;
      align-items: center;
      gap: 6px;
    }

    .pdf-link svg {
      width: 20px;
      height: 20px;
    }


    #chat-container {
        #status-box {
            height: 23px;
            padding: 5px;
            font-style: italic;
            color: gray;
            text-align: center;
            position: absolute;
            bottom: 30px;
            left: 60px;
            font-size: 18px;
        }


        #preloader {
            display: block;
            position: absolute;
            bottom: 26px;
            left: 34px;
        }

        #chat-box {
            position: relative;
            display: flex;
            flex-direction: column;
            /* Reverse the flex direction */
            flex-grow: 1;
            max-height: 100%;
            overflow-y: auto;
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 10px;
        }

        #chat-box .message-container {
            margin: 5px 0;
            position: relative;
        }

        #chat-box .message-container.sender-you {
            align-self: flex-end;

        }

        #chat-box .message-container {
            max-width: 60%;
            border-radius: 4px;
            padding-top: 30px !important;
        }

        #chat-box .message-container.sender-impactchat {

            background-color: rgb(0 159 254);
            color: white;
            padding: 15px;
            margin: 5px 0;
            /* border: 2px solid blue; */
        }

        #chat-box .message-container.sender-llm {
            background-color: rgba(0, 161, 254, 0.8);
            color: white;
            padding: 15px;
            margin: 5px 0;
            margin-bottom: 5px;
        }

        .sender-you .sender-name {
            display: none;
        }

        .sender-impactchat .sender-name {
            position: absolute;
            top: 5px;
            left: 5px;
            font-weight: bold;
        }

        .sender-impactchat.llm {
            opacity: .7 !important;
        }

        .sender-you .message-time {
            position: absolute;
            bottom: -13px;
            min-width: 100px;
            right: 0px;
            text-align: right;
            font-size: 10px;
        }

        .sender-impactchat .message-time {
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 10px;
        }



        .message-time {
            font-size: 0.8em;
            color: gray;
        }

        #chat-box p.sender-impactchat .message-time {
            color: white;
        }

        #chat-box p.sender-llm .message-time {
            color: white;
        }

        #input-container {
            display: flex;
        }

        #message-input {
            flex-grow: 1;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        #send-btn {
            padding: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin-left: 5px;
        }


        .loader {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #3498db;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 1s linear infinite;
        }

        .checkmark {
            font-size: 20px;
            color: green;
            display: none;
            /* Hidden by default */
        }

        .hidden {
            opacity: 0;
            pointer-events: none;
            /* display: none; */
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
